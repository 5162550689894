<template>
    <section class="footer">
    <div class="footer-top">
      <div class="w-row">
        <div class="w-col w-col-2">
          <div class="menu-footer">
            <router-link class="link-menu-footer w-inline-block" :to="{name:'home'}">
              <div>Home</div>
            </router-link>
            <router-link class="link-menu-footer w-inline-block" :to="{name:'about'}">
              <div>Chi siamo</div>
            </router-link>
            <router-link class="link-menu-footer w-inline-block" :to="{name:'progetto'}">
              <div>Progetto</div>
            </router-link>
            <router-link class="link-menu-footer w-inline-block" :to="{name:'soluzioni'}">
              <div>soluzioni</div>
            </router-link>
            <a class="link-menu-footer w-inline-block" href="https://shop.damand.beauty">
              <div>Shop</div>
            </a>
            <router-link class="link-menu-footer w-inline-block" :to="{name:'contatti'}">
              <div>contatti</div>
            </router-link>
          </div>
        </div>
        <div class="w-col w-col-8">
          <div class="box-newsletter">
            <h3 class="titolo-newsletter">Iscriviti alla newsletter</h3>
            <div class="desc-newsletter">Entra a far parte del mondo DAMAND e resta sempre aggiornato sulle novità</div>
            <a class="btn-newsletter w-button" href="https://shop.damand.beauty/pages/iscrizione-newsletter">iscriviti</a>
          </div>
        </div>
        <div class="w-col w-col-2">
          <div class="box-social-footer">
            <a href="https://www.instagram.com/damand____/" target="_blank" class="link-social-footer w-inline-block">
              <div>instagram</div>
            </a>
          </div>
          <div class="box-locator-footer">
            <div class="titolo-store-locator">Cerca il negozio <br>più vicino a te</div>
            <router-link class="btn-footer-locator w-button" :to="{name:'store'}">
              store locator
            </router-link>
          </div>
        </div>
      </div>
      <div class="w-row">
        <div class="w-col w-col-2"></div>
        <div class="w-col w-col-8">
          <div class="box-copyright">
            <div class="text-copyright">Damand srl | Via Malta 39/f 25124 Brescia | 2023 tutti i diritti riservati | <a href="https://www.iubenda.com/privacy-policy/69043480" class="iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe " title="Privacy Policy ">Privacy Policy</a> | <a href="https://www.iubenda.com/privacy-policy/69043480/cookie-policy" class="iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe " title="Cookie Policy ">Cookie Policy</a> | <a href="https://noemstudio.it/credits.html" target="_blank" class="iubenda-nostyle">Credits</a></div>
          </div>
        </div>
        <div class="w-col w-col-2"></div>
      </div>
    </div>
    <div class="footer-bottom">
      <div data-w-id="be47235d-e384-2733-e2b3-fcc157f7ba23" class="scritta-footer">DAMAND</div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FooterDamand',
  props:{
    
  },
  methods:{
  }
}
</script>